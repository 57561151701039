<template>
  <div>Liste des consentements</div>
</template>

<script>
// import layoutDatabase from "../../layouts/layout-database.vue";
import layoutHome from '../../layouts/layout-home.vue'
export default {
  created() {
    this.$emit('update:layout', layoutHome)
  }
}
</script>

<style></style>
